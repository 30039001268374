export default {
  'en-US': {
    compareTable: {
      notIncluded: 'Not Included',
      included: 'Included',
    },
    cookieConsent: {
      p: 'This website uses cookies to improve your experience. By continuing to browse the site, you are agreeing to our use of cookies. Review our {link} for more details.',
      link: 'Privacy Policy',
    },
    copyright: `Copyright ${new Date().getFullYear()} Neat | All Rights Reserved`,
    cta: {
      buyNow: 'Buy now',
      buyNowRenew: 'Buy now - Save $50',
      buyNowRenewOffer: 'Buy now - Save $100',
      buyNowMigrationSupport: 'Buy now - Save $100',
      buyNowAndSave: 'Buy now and save $100',
      continue: 'Continue',
      close: 'Close',
      dismiss: 'Dismiss',
      finish: 'Finish',
      freeTrial: 'Free trial',
      getStarted: 'Get Started',
      keepMyFiles: 'Keep my files',
      learnMore: 'Learn more »',
      login: 'Log In',
      processing: 'Processing',
      seePlansAndPricing: 'See plans and pricing',
      signIn: 'Sign In',
      signUpNow: 'Sign up now',
      submitting: 'Submitting...',
      subscribe: 'Subscribe',
      tryNeatFREE: 'Try Neat FREE',
      tryNeatFree: 'Try Neat Free',
      unsubscribe: 'Unsubscribe',
      getNotified: 'Get Notified',
      start14DayTrial: 'Start your 14-Day Trial',
      iWantTheOffer: 'Yes, I want the offer',
      tryNeatHome: 'Try NeatHome free',
      tryNeat: 'Try Neat',
      tryNeatTrial: 'Try Neat Trial',
      buyUnderSeven: 'Buy NeatHome now under $7/mo',
      templateDownload: 'Download your free template',
      voteAndEnter: 'Vote & Enter Now',
      uncoverDates: 'Uncover all dates',
      coverDates: 'Cover all dates',
      noCcTrial: 'START NO-CC TRIAL',
    },
    featuresFooter: {
      header: "Let's Get Started",
      p: 'Ready for more confidence?{br}Try Neat today for free.',
      smallText: "Neat's full suite platform offers a 30-day money back guarantee",
    },
    footer: {
      appStore: {
        alt: 'Download on the App Store',
        title: 'Apple App store - link to Neat app',
      },
      playStore: {
        alt: 'Get it on Google Play',
        title: 'Google Play store - link to Neat app',
      },
      bbbAlt: 'BBB Accredited Business',
      mailingList: {
        header: 'Subscribe',
        label: 'Subscribe to get tips and offers designed to help grow your business.',
      },
      navLists: {
        company: 'Company',
        product: 'Product',
        resources: 'Resources',
      },
      recaptchaTerms: 'This site is protected by reCAPTCHA and the Google {privacyLink} and {termsLink} apply.',
    },
    header: {
      features: 'Features',
      product: 'Product',
    },
    label: {
      email: 'Email',
      firstName: 'First Name',
      lastName: 'Last Name',
      password: 'Password',
      plan: 'Plan',
      phone: 'Mobile Number',
    },
    navLinks: {
      accountants: 'Accountants',
      accountingTerms: 'Accounting Terms Glossary',
      automatedInsights: 'Automated Insights',
      balanceSheetTemplate: 'Free Balance Sheet Template',
      webinars: 'Webinars',
      blog: 'Blog',
      careers: 'Careers',
      features: 'Features',
      incomeStatementTemplate: 'Free Income Statement Template',
      helpCenter: 'Help Center',
      integrations: 'Integrations',
      invoiceTemplate: 'Free Invoice Template',
      learn: 'Learn',
      neatFeatures: 'Neat',
      ourHistory: 'About Neat',
      policies: 'Policy Center',
      pricing: 'Pricing',
      product: 'Product',
      productReleases: 'Product Releases',
      receiptDocumentScanners: 'Compatible Scanners',
      security: 'Security',
      support: 'Support',
      taxPrep: 'Prep Taxes',
      trackReceipts: 'Track Receipts',
      transactionStreaming: 'Reconcile',
      vipService: 'VIP Service',
      expenseManagementMobileApp: 'Mobile App',
    },
    featureRows: {
      trackReceipts: {
        title: 'Track Receipts Everywhere',
        text: 'Capture receipts, invoices, and so much more with the snap of a picture. Then upload them straight to Neat using the app. Even email, drag and drop documents into Neat, or import with a compatible scanner.[br][br]You’ll always have access to your documents in Neat.',
      },
      dataExtraction: {
        title: 'Data Extraction',
        text: 'Reduce manual data entry with Neat’s powerful, patented OCR technology that accurately extracts key information from your document and turns it into usable data allowing you to enter receipts quickly and accurately.',
      },
      dataVerification: {
        title: 'Data Verification',
        text: 'Your receipt items are processed using Neat’s unique data identification technology and data is extracted with 99% accuracy.',
      },
      organizeFiles: {
        title: 'Organize Files Your Way',
        text: 'Organize files by expense type, tax category, and more. Quickly find files with our keyword search and filtering capabilities.',
      },
    },
    pages: {
      automatedInsights: {
        main: {
          header: {
            title: 'Automated Insights',
            subtitle: 'Financial reporting made easy so you can spend time focused on where you want it.',
            smallPrint: 'Automated Insights cannot be purchased without a Neat subscription.',
            descriptionImgOne: 'Analyze your finances with live data',
            descriptionImgTwo: 'Easily monitor top expense categories & cash balances',
            altImgOne: 'Live data',
            altImgTwo: 'Monitor expense & cash',
          },
          features: {
            connectBankAccount: 'Manage all of your financial data in one place by importing all of your bank account(s) activity.',
            pairReceipts: "Use Neat's mobile app to upload and match receipts to expenses as they occur. Guided workflows are provided with suggested receipt matching to make accounting easy.",
            accountingReports: 'Make informed decisions with automatic, real-time accounting reports such as profit and loss statements, cash flow statements, balance sheets, and transaction reports.[br]Download and share reports with anyone who may need to view it for accounting or record keeping purposes.',
            realTimeInsights: 'Know what is going on in your business at a glance, with real-time insights - including net cash flow, top expense categories, cash balance, profit and loss, and total sales.',
            customInvoices: 'Send customized invoices within minutes.',
            onlinePayments: 'Stop waiting for checks in the mail. Receive safe and secure online payments by enabling your customers to pay you by bank transfers and credit/debit card*.',
          },
        },
      },
      careers: {
        header: {
          title: 'Come Work With Us',
          subtitle: 'Neat is a mixture of seasoned professionals and dynamic young leaders that together create a unique blend of experience and energy. Our employees combine humor with humility in a highly collaborative, open-office environment geared towards creative problem solving and rapid decision making.',
        },
        whatWeOffer: {
          title: 'What Do We Offer?',
          medical: {
            name: 'Medical, Dental, & Vision',
            p: 'Subsidized coverage for employees and families. Option for high-deductible plan with generously funded HSA plan.',
          },
          pto: {
            name: 'Paid Time Off',
            p: "We know committing to getting things done can impact your work-life balance. It's important to take the time to refresh.",
          },
          holidays: {
            name: 'Holidays',
            p: '9 paid company holidays and summer hours.',
          },
          transit: {
            name: 'SEPTA Ride Share',
            p: 'Tax-free transit',
          },
          salary: {
            name: 'Competitive Salary',
            p: 'Competitive salary, stock options and 401(k) with company match.',
          },
          environment: {
            name: 'Productive Environment',
            p: 'A casual, entrepreneurial environment of ownership and accountability, enabling you to get your work done. ',
          },
        },
        openings: {
          title: 'Openings',
          noOpenings: 'No openings at this time. Please check back later.',
        },
      },
      deleteAccount: {
        header: 'I Still Want to Delete My Account',
        confirmation: 'If you wish to have the account associated with your email address <strong>({email})</strong> completely deleted from the Neat application, then please press the button below.',
        cta: 'Delete account',
        unavailable: 'Account deletion is unavailable.',
        success: {
          received: 'Your account deletion request has been received and will be processed shortly.',
          note: 'Please note, account deletion requests can sometimes take upwards of 7-10 days. You will receive a confirmation via email once your account has been deleted.',
          email: 'Email Address: <strong>{email}</strong>',
        },
      },
      emailPreferences: {
        subscribe: {
          header: 'Subscribe',
          p1: 'Subscribe here to get tips and offers designed to help grow your business straight to your inbox.',
          p2: 'We send emails a few times a month, so once you subscribe you can expect to hear from us soon.',
          success: 'You have successfully subscribed to receive emails from Neat!',
        },
        unsubscribe: {
          header: 'Unsubscribe',
          p1: 'If you would like to unsubscribe from receiving marketing emails from Neat, you may opt out here. By opting out, you will no longer receive tips and offers from Neat. You will still receive important account and billing related emails from Neat.',
          success: 'Your opt-out request has been confirmed. Please allow 48 hours for your request to fully process. You can re-subscribe anytime by filling out the form above.',
        },
      },
      fbRenew: {
        main: {
          header: {
            title: 'A smarter way to manage your receipts and documents',
            subtitle: 'Simplify how you work',
          },
        },
        security: {
          title: '20 years of security and data protection',
          p: 'In tech world years, two decades equates to centuries. With that experience comes a deep knowledge of data security. Because we use the highest standard of bank-level encryption, your data is kept secure from any breaches.',
        },
      },
      home: {
        main: {
          header: {
            title: 'A smarter way to manage your receipts and documents',
            subtitle: 'Simplify how you work',
          },
        },
        features: {
          title: 'Report expenses, manage documents, and much more!',
          subtitle: 'Save time and get to what matters',
          li1: 'Access real-time visibility into expenses, spending, sales, and income tax for easy filing and reporting.',
          li2: "Avoid manual data entry with Neat's patented technology that accurately extracts key information from your document.",
          li3: 'Upload receipts and documents securely with your mobile device or scanner.',
          cta: "Learn more about Neat's features",
          cabinetAlt: 'Neat File Management Screenshot',
        },
        quotes: {
          quote1: "“It pulls the information off the page, actually reads the text that's on there, and organizes it for you.“",
          authorPrefix: '-',
          author1: 'Josh Z., Certified Professional Organizer',
          quote2: "“It's simply more enjoyable to live in a space that's organized.“",
          author2: 'Bonnie D., Certified Professional Organizer',
          quote4: '“The system is fantastic and eliminates many hours of filing and finding files.“',
          author4: 'Andy K.',
          quote3: "“I have been using Neat for at least 7 years, and it's made tracking receipts and generating year end reports a breeze.“",
          author3: 'Joann D.',
          quote5: '“Makes end of the year filing so easy! No need to save receipts.“',
          author5: 'Michael K.',
        },
        stats: {
          waste: {
            stat: '92',
            unit: '%',
            details: 'waste time searching for important documents',
          },
          efficiency: {
            stat: '10',
            unit: 'X',
            details: 'more efficiency in searching for files',
          },
          protection: {
            stat: '100',
            unit: '%',
            details: 'file protection from natural disasters',
          },
          callout: {
            title: 'Join over 2 million small businesses',
            subtitle: 'who have trusted Neat services',
          },
        },
        security: {
          title: '20 years of security and data protection',
          p: 'In tech world years, two decades equates to centuries. With that experience comes a deep knowledge of data security. Because we use the highest standard of bank-level encryption, your data is kept secure from any breaches.',
        },
      },
      integrations: {
        main: {
          title: 'Integrations',
          subtitle: 'Connect Seamlessly with Business Software',
          tabs: {
            support: '<strong>Visit our support center for more information on integrating {linkCount, plural, one {{link}} other {{link} or {link2}}}</strong>',
            quickbooks: {
              title: 'QuickBooks<sup>®</sup>',
              subtitle: 'Eliminate Data Entry With Neat and QuickBooks',
              description: 'Using Neat with QuickBooks Online simplifies accounting by reducing data entry and automatically organizing expenses.',
              li1: 'Review and send all expense-related data directly over to QuickBooks. Images of receipts and invoices are automatically attached.',
              li2: 'Sign in to QuickBooks from the Neat app to connect.',
              li3: 'Map expenses once to match your QuickBooks preferences and future data will map automatically.',
              supportLink: 'QuickBooks Online',
            },
            hrblock: {
              title: 'H&R Block<sup>®</sup>',
              subtitle: 'Simplify tax filings and maximize deductions with Neat with H&R Block',
              li1: 'Scan receipts and invoices to track expense documents throughout the year.',
              li2: 'Save time by eliminating the need to key in all data from expense-related documents.',
              li3: "Avoid potential errors with Neat's machine-learned data automation and send directly to H&R Block.",
              li4: 'Utilize tax categories in Neat throughout the year, and H&R Block will use that information to create your tax forms.',
              supportLink: 'H&R Block',
            },
            quicken: {
              title: 'Quicken<sup>®</sup>',
              subtitle: 'Simplify Accounting & Bookkeeping with Neat and Quicken.',
              li1: 'Scan receipts and invoices to track expenses throughout the year.',
              li2: 'Save time by eliminating the need to key in all data from expense-related documents.',
              li3: "Avoid potential errors with Neat's machine-learned data automation and send directly to Quicken.",
              supportLink: 'Quicken',
            },
            turbotax: {
              title: 'TurboTax<sup>®</sup>',
              subtitle: 'Simplify tax filings and maximize deductions with Neat and TurboTax.',
              li1: 'Scan receipts and invoices to track expenses throughout the year.',
              li2: 'Save time by eliminating the need to key in all data from expense-related documents.',
              li3: "Avoid potential errors with Neat's machine-learned data automation and send directly to TurboTax.",
              li4: 'Utilize tax categories in Neat throughout the year, and TurboTax will use that information to create your tax forms.',
              supportLink: 'TurboTax',
            },
            outlook: {
              title: 'Outlook<sup>®</sup>',
              subtitle: 'Never lose a contact again when you sync Neat with Outlook Contacts.',
              li1: 'Turn your stack of business cards into a digital contact list.',
              li2: 'Seamlessly forward contacts to Outlook Contacts.',
              li3: 'Organize contacts by project, event, region, and create a distribution list for sales emails.',
              li4: 'Utilize tax categories in Neat throughout the year, and TurboTax will use that information to create your tax forms.',
              supportLink: 'Outlook',
            },
            campaignMonitor: {
              title: 'Campaign Monitor<sup>®</sup>',
              subtitle: 'Focus on creating compelling campaigns and less on entering in contact information when you link Neat and Campaign Monitor.',
              li1: 'Automate and digitize your stack of business cards in minutes.',
              li2: 'Seamlessly forward contacts to specific email streams in Campaign Monitor.',
              li3: 'Organize contacts by project, event, region, and create a distribution list for sales emails.',
              supportLink: 'Campaign Monitor',
            },
            mailchimp: {
              title: 'MailChimp<sup>®</sup>',
              subtitle: 'Scan business cards with Neat to add contacts to your MailChimp Campaign.',
              li1: 'Automate and digitize your stack of business cards in minutes.',
              li2: 'Seamlessly forward contacts to specific email streams in MailChimp.',
              li3: 'Organize contacts by project, event, region and create a distribution list for sales emails.',
              supportLink: 'MailChimp',
            },
            googleContacts: {
              title: 'Google Contacts<sup>®</sup>',
              subtitle: 'Sync Neat with Google Contacts and never lose a contact again.',
              li1: 'Automate and digitize your stack of business cards in minutes.',
              li2: 'Seamlessly sync contacts to Google Contacts.',
              li3: 'Organize contacts by project, event, region, and create a distribution list for sales emails.',
              supportLink: 'Google Contacts',
            },
            constantContact: {
              title: 'Constant Contact<sup>®</sup>',
              subtitle: 'Use Neat with Constant Contact to spend less time on data entry and more time creating killer email campaigns.',
              li1: 'Automate and digitize your stack of business cards in minutes; or simply add them to Neat as you get them.',
              li2: 'Seamlessly forward contacts to specific email streams in Constant Contact.',
              li3: 'Organize contacts by project, event, region and create a distribution list for sales emails.',
              supportLink: 'Constant Contact',
            },
          },
        },
      },
      invoiceTemplate: {
        download: {
          excel: 'Download your free invoice template (Excel)',
          google: 'Download your free invoice template (Google Sheet)',
        },
        tryNeatTool: {
          button: 'Start Invoicing Today',
        },
        featuresFooter: {
          header: 'Try Neat\'s Invoicing Tool',
          button: 'Start Invoicing Today',
          text: 'Invoicing + File Document/Receipt Management + Financial reporting',
        },
      },
      migrationSupport: {
        main: {
          header: {
            title: 'Migrate and store your Neat data with help from our VIP support team',
            subtitle: 'Simplify how you work',
          },
        },
        security: {
          title: '20 years of security and data protection',
          p: 'In tech world years, two decades equates to centuries. With that experience comes a deep knowledge of data security. Because we use the highest standard of bank-level encryption, your data is kept secure from any breaches.',
        },
      },
      neatFeatures: {
        main: {
          title: 'Neat Features',
          subtitle: 'Smart file organization designed to declutter, increase efficiency, and gain peace of mind.',
          smallPrint: 'Neat is a yearly subscription where you can add on solutions.',
          descriptionImgOne: 'Declutter with clear organization',
          descriptionImgTwo: 'Manage every type of file efficiently',
          altImgOne: 'Organize files',
          altImgTwo: 'All files',
        },
      },
      notFound: {
        h2: 'Oh No! The page you requested could not be found.',
        popular: 'Popular Pages:',
        links: {
          support: 'Neat’s Support Center',
          plansAndPricing: 'Plans and Pricing',
          blog: 'Neat’s Blog',
          contactUs: 'contact us',
          cannotFind: 'If you can’t find what you’re looking for, {link}.',
        },
      },
      pricing: {
        header: {
          title: 'Transform the way you manage and organize your home or business',
        },
        faq: {
          title: 'Frequently Asked Questions',
          notReady: {
            title: "I'm not ready to make a decision. Do you offer a free trial?",
            p: "Yes, Neat offers a free 14-day trial. During those 14 days, you'll receive full access to the standard Neat plan. After the trial, you can continue with Neat or cancel risk-free. Click the link below to start your free trial today!",
          },
          moneyBack: {
            title: 'Does Neat offer a money-back guarantee?',
            p: 'Yes, if you decide that Neat is not the right solution for you, Neat offers a full 30-day money-back guarantee.',
          },
          support: {
            title: 'What kind of support services does Neat come with?',
            p: 'Neat comes with unlimited email and chat support. Also, you will have access to our Help Center Knowledge Base. You will need to purchase VIP Service if you want unlimited phone and the ability to schedule 1:1 training.',
          },
          storage: {
            title: 'How much document storage do I get a month?',
            p: 'Neat offers unlimited monthly storage.',
          },
          payMonthly: {
            title: 'Can I pay monthly?',
            p: 'No, Neat does not offer a monthly payment plan. All plans are billed yearly.',
          },
          insightsAndVip: {
            title: 'Can I purchase Neat + Automated Insights + VIP Service?',
            p: 'Yes, if you click on any Buy Now button, you will be able to select which solutions you want. Just remember, you cannot purchase Automated Insights or VIP Service without a Neat subscription.',
          },
        },
        offers: {
          title: 'Enhance your base subscription',
          restriction: 'Cannot purchase without a Neat subscription',
          restrictionNeatHome: 'Cannot purchase without a NeatHome subscription',
          buyCta: 'Buy Neat + {type}',
          buyNeatHomeCta: 'Buy NeatHome + {type}',
          learnMoreCta: 'Learn more about {type} →',
          vipService: {
            title: 'VIP Service',
            subtitle: 'Neat + $50/year',
            description: 'Everything you need, plus premium help from our specialized support team.',
            li1: '1:1 training guided by Neat Experts',
            li2: 'Dedicated support team so you can skip the line',
            li3: 'Early access to new features',
            li4: 'Exclusive offers',
          },
          automatedInsights: {
            title: 'Automated Insights',
            subtitle: 'Neat + $150/year',
            description: 'Gain valuable insights into your finances with real-time accounting and expense reporting.',
            li1: 'Import bank account activity',
            li2: 'Automatic accounting reports',
            li3: 'Real-time insights and trends',
            li4: 'Pair receipts with expenses',
            li5: 'Send custom invoices',
          },
          vipNeatHomeService: {
            title: 'VIP Service',
            subtitle: 'NeatHome + $50/year',
            description: 'Everything you need, plus premium help from our specialized support team.',
            li1: '1:1 training guided by Neat Experts',
            li2: 'Dedicated support team so you can skip the line',
            li3: 'Early access to new features',
            li4: 'Exclusive offers',
          },
        },
      },
      receiptDocumentScanners: {
        main: {
          subtitle: 'Compatible Receipt & Document Scanners.',
          description: 'Neat works with any TWAIN compliant scanner.',
          viewScanners: 'View All Compatible Scanners',
        },
      },
      renew: {
        featuresSection: {
          trackReceipts: {
            title: 'Track Receipts Everywhere',
            text: 'Capture receipts, invoices, and so much more with the snap of a picture. Then upload them straight to Neat using the app. Even email, drag and drop documents into Neat, or import with a compatible scanner.[br]You’ll always have access to your documents in Neat.',
          },
          dataExtraction: {
            title: 'Data Extraction',
            text: 'Reduce manual data entry with Neat’s powerful, patented OCR technology that accurately extracts key information from your document and turns it into usable data allowing you to enter receipts quickly and accurately.',
          },
          dataVerification: {
            title: 'Data Verification',
            text: 'Your receipt items are processed using Neat’s unique data identification technology and data is extracted with 99% accuracy.',
          },
          organizeFiles: {
            title: 'Organize Files Your Way',
            text: 'Organize files by expense type, tax category, and more. Quickly find files with our keyword search and filtering capabilities.',
          },
        },
      },
      security: {
        main: {
          title: 'Security',
          subtitle: 'Keeping Your Information Secure is Our Top Priority.',
          description: 'Neat uses best-in-class security measures to keep your data protected and safe.',
        },
      },
      taxPrep: {
        main: {
          integrationsCta: 'See All Integrations',
          title: 'Taxes',
          subtitle: 'Simplify Tax Prep',
          description: 'Our tax solution tags expenses and retains records to maximize deductions and audit-proof your business.',
          stayOrganized: {
            title: 'Stay Organized Year-Round',
            p: '<strong>Throw away those old accordion folders.</strong> With Neat, you can scan, snap a picture, email, or drag and drop documents to digitize them and make them keyword searchable automatically. Then, organize your files however you like. Want to automatically tag items by expense type? Done. Want to make notes on specific expenses? No problem. Neat works around your organizational style.',
          },
          checkReceipts: {
            title: 'Check Receipts Against Bank Transactions',
            p: '<strong>Have confidence that all of your transactions are centralized and accurate.</strong> No need to reconcile off of a bank statement. Connect to any of +10,000 financial institutions to match receipts and invoices directly to your bank or credit card transactions.',
          },
          fileTaxes: {
            title: 'File Your Taxes Quickly (And Accurately)',
            p: '<strong>Easily send expenses to your tax or accounting software.</strong> Map expenses a single time to match your QuickBooks preferences, and incoming data will be automatically coded. Export tax categories, expenses, and document images to tax software like TurboTax to eliminate the need to type in data.',
            cta: 'View All Integrations',
          },
          communicate: {
            title: 'Easily Communicate With Your Accountant',
            p: '<strong>Tired of long email chains?</strong> Send items to your accountant or tax preparer directly from Neat. Every item and folder in Neat also allows you to add and receive comments, making collaboration a breeze.',
          },
        },
      },
      transactionStreaming: {
        header: {
          title: 'Reconcile',
          subtitle: 'All of Your Transactions. One Convenient Place.',
          description: 'Effortlessly keep your books neat. No accounting expertise required.',
        },
        reconcile: {
          cta: 'Using Neat for tax prep',
        },
      },
      vipService: {
        features: {
          scheduleTraining: 'Tell us what topics you would like to cover during the session and let our Neat Experts guide you through making the most out of Neat.[br]1:1 training includes:',
          supportTeam: 'Cut the line and get priority phone, chat, and email support. Our dedicated VIP support team will prioritize your needs and provide exceptional care for all your customer service and technical support needs.[br][br]Our dedicated VIP support team is available M-F 9:00am-8:00pm EST',
          exclusiveOffers: 'Get early access to new features in beta, as well as exclusive offers to share with friends and family!',
          welcomeEmail: 'A welcome email will be sent with detailed information on how to schedule your 1:1 training and contact your dedicated support team.',
        },
      },
      expenseManagementMobileApp: {
        features: {
          transactionsInApp: 'Effortlessly connect your bank accounts, credit cards, and digital wallets into one powerful dashboard. Edit transactions with a tap, categorize expenses instantly, and gain total control over your financial narrative.',
          docCaptureImport: 'Our mobile app transforms your smartphone into a scanning powerhouse. Snap crystal-clear pictures of receipts and invoices instantly within the mobile app, turning your device into a financial documentation tool with just one click. Beyond camera capture, you can also seamlessly import documents directly from your device\'s photo gallery.',
          manageDocuments: 'Quickly review transactions and accounts from anywhere—in line, waiting, or spare moment. Effortlessly reconcile financial records by matching streamed transactions with uploaded receipts.',
          accessOrganizeDocs: 'Take charge of your documents on the go with the Neat mobile app\'s intuitive file management tools. Share PDFs, move items, send files to trash, duplicate folders, and copy documents—all within the mobile app\'s powerful interface. Your digital filing cabinet has never been more flexible.',
          easySearching: 'Find any document instantly with Neat\'s powerful search functionality. Quickly locate receipts, invoices, and files using keywords, dates, or categories, transforming document retrieval from a tedious task into a seamless, lightning-quick experience.',
        },
      },
      signUp: {
        create: { success: 'Successful order' },
        form: {
          selectPlan: {
            nextButton: 'Continue',
            baseTooltip: 'Neat base subscription is required to continue.',
            side: {
              features: {
                files: 'Unlimited file storage',
                'data-capture': 'Data capture with human verification',
                'unlimited-support': 'Unlimited email & chat support',
                expenses: 'Track your expenses',
                filter: ' Easily filter and search for documents',
                'file-sharing': 'File sharing and collaboration',
                encryption: 'Bank-level security encryption',
                accessible: 'Accessible from anywhere',
                'help-center': 'Access to our Help Center Knowledge Base',
              },
            },
          },
          authenticate: {
            nextButton: 'Get Started',
          },
          newUser: {
            nextButton: 'Finish',
          },
          billing: {
            submit: 'Finalize Purchase',
            addCoupon: 'Apply',
            removeCoupon: 'Remove',
            submitTrial: 'Start My FREE 14-Day Trial',
            paypal: 'Continue to PayPal',
          },
          existingUser: {
            welcomeBack: 'Welcome back!',
            resetPassword: 'Reset my password',
            greeting: 'Hi <strong>{email}</strong>',
          },
          coupon: {
            removeCoupon: 'Remove Coupon',
          },
          upsell: {
            getVip: 'Yes, give me VIP Service',
          },
        },
        unexpectedError: 'Oops! We encountered an unexpected error and our engineers have been alerted. If the problem persists, please contact Neat customer support.',
        noCcAiVipError: 'It looks like you already have access to all the features of Neat + Automated Insights, sign-in to your account to get started.',
        requestValidationError: 'Oops! We encountered an unexpected error and our engineers have been alerted. If the problem persists, please contact Neat customer support for assistance placing your order.',
      },
      blog: {
        title: 'The Neat Small Business Blog',
        description: 'Insights, news, and resources to help inform all aspects of running a business.',
        featuredArticles: 'Featured Articles',
        popularArticles: 'Popular',
        recentArticles: 'Recent',
        navigation: {
          accounting: 'Accounting & Bookkeeping',
          taxTime: 'Tax Time',
          smallBusiness: 'Small Business Resources',
          contractor: 'Contractor Resources',
          news: 'Neat News',
          homeFinance: 'Home Finance',
        },
        readMore: 'Read More >',
      },
      freeTrial: {
        form: {
          submit: 'Start My 30-Day Free Trial',
          error: {
            orderFailed: 'There was an error placing the order. Please try again.',
          },
        },
        ctaText: 'Start Your 30-Day Free Trial',
      },
    },
    placeholder: {
      email: 'Email',
    },
    templates: {
      balanceSheetDownloadMessage: 'Thanks! We hope you enjoy this balance sheet template.',
      incomeStatementDownloadMessage: 'Thanks! We hope you enjoy this income statement template.',
    },
  },
}
